import React from 'react';
import { Avatar } from '../components/Avatar';

export const mapAvatars = (users, maxAvatars) => {
  if (!users || !users.length) return null;

  // If less than or equal to maxAvatars contacts, render all avatars
  if (users.length <= maxAvatars) {
    return (
      <span className="avatars">
        {users.map(contact => (
          <Avatar key={contact.id} {...contact} size="xsmall" showTooltip />
        ))}
      </span>
    );
  }

  // If more than maxAvatars contacts, show first maxAvatars and remainder count
  const visibleUsers = users.slice(0, maxAvatars);
  const remainderCount = users.length - maxAvatars;
  const remainingUsers = users.slice(maxAvatars);

  return (
    <span className="avatars">
      {visibleUsers.map(contact => (
        <Avatar key={contact.id} {...contact} size="xsmall" showTooltip />
      ))}
      <Avatar
        remainder={remainderCount}
        tooltipText={remainingUsers.map(user => user.label)}
        size="xsmall"
        primaryBg
        showTooltip
      />
    </span>
  );
}; 