import React, { useRef, useState } from 'react';
import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import './DatePicker.scss';

const CONST = {
  invalidDateMessage: 'Invalid Date',
};

export const DatePicker = props => {
  const { input, placeholder, disabled, defaultDate, customOptions, defaultAutocomplete } = props;
  const refComp = useRef(null);
  const [isValidDate, setIsValidDate] = useState(true);
  const defaultOptions = {
    allowInput: true,
    dateFormat: 'd/m/Y',
  };

  const clearDate = () => {
    refComp.current.flatpickr.clear();
  };

  const handleOnChange = event => {
    setIsValidDate(event.length > 0);
  };

  return (
    <>
      <div className="datepicker-container" style={{ position: 'relative' }}>
        <Flatpickr
          options={{ ...defaultOptions, ...customOptions }}
          {...input}
          placeholder={placeholder}
          disabled={disabled}
          defaultDate={defaultDate}
          ref={refComp}
          onChange={handleOnChange}
          autocomplete={defaultAutocomplete || "off"}
          aria-invalid={!isValidDate}
          aria-describedby={`dateError-${input.name}`}
        />
        {input?.value?.length !== 0 && (
          <button
            type="button"
            className="icon icon-cross-red clear-datepicker-btn"
            style={{
              background: 'none',
              border: 'none',
              top: '13px',
              right: '3px',
              position: 'absolute',
              display: 'flex',
            }}
            onClick={clearDate}
          />
        )}
      </div>
      {!isValidDate && (
        <small className="invalid" id={`dateError-${input.name}`}>
          {CONST.invalidDateMessage}
        </small>
      )}
    </>
  );
};
